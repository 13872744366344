import React, { Suspense, useState, useEffect, useMemo } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../node_modules/font-awesome/scss/font-awesome.scss';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { AppContext } from './context';
import Loader from './layout/Loader'
import Aux from "./hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "./route";
import { checkToken } from './utils/authority';
import history from './utils/history';
import { REQUEST_STATUS } from './utils/constants/auth';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

export default function App() {
const [error, setError] = useState();
    const [authStatus, setAuthStatus] = useState();

    const context = useMemo(() => ({ error, setError, auth: authStatus, setAuthStatus }), [
        error,
        authStatus,
        setError,
        setAuthStatus,
    ]);

    const menus = routes.map((route, index) => {
        return (route.component) ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                    <route.component {...props} />
                )} />
        ) : (null);
    });

    useEffect(() => {
        function checkAuth() {
            const token = checkToken();
            if (token) {
                setAuthStatus(REQUEST_STATUS.AUTHENTICATED)
            } else {
                setAuthStatus(REQUEST_STATUS.UNAUTHENTICATED);
                return <Redirect to='/auth/signin' />;
            }
        }

        checkAuth();
    }, []); // only on first render

     return (
        <AppContext.Provider value={context}>
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Router history={history}>
                            <Switch>
                                {menus}
                                <Route path="/" component={AdminLayout} />
                            </Switch>
                        </Router>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        </AppContext.Provider>
    )
}
