import jwt from 'jsonwebtoken';

export function getToken() {
  return jwt.decode(localStorage.getItem('token'));
}

export function checkToken() {
  const token = localStorage.getItem('token');

  if (!token) return false;

  try {
    const date = new Date(0);
    const decoded = jwt.decode(token);
    date.setUTCSeconds(decoded.exp);
    return date.valueOf() > new Date().valueOf();
  } catch (err) {
    return false;
  }
}

export function getRole() {
  const token = localStorage.getItem('token');

  if (!token) return false;

  try {
    const decoded = jwt.decode(token);
    return decoded.userType
  } catch (err) {
    return false;
  }
}

export function getMe() {
  const token = localStorage.getItem('token');

  if (!token) return false;

  try {
    const decoded = jwt.decode(token);
    return decoded
  } catch (err) {
    return false;
  }
}

export function logout() {
  localStorage.removeItem('token');
  // TODO: arif kalau ada cara yg lebih oke, monggo disikat
  window.location.reload(false);
}
